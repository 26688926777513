import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  AuthManagementApiService,
  ForgotPasswordCommand,
  GetToken,
  LoginCommand,
  RegistrationCommand,
  ResetPasswordCommand,
} from '@api-open';
import { CONFIG } from '@config';
import { DecodedToken } from '@shared/models/token.model';
import { LoginRedirectService } from '@shared/services';
import { REFRESH_TOKEN, TOKEN } from '@shared/string-constants';
import { decodeToken } from '@utils/jwt.util';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  logoutEvent$: Observable<void>;

  baseUrl = CONFIG.apiUrl;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private decodedToken: DecodedToken = undefined!;

  private logout$ = new Subject<void>();

  constructor(
    private authManagementApiService: AuthManagementApiService,
    private dialog: MatDialog,
    private router: Router,
    private location: Location,
    private loginRedirectService: LoginRedirectService,
  ) {
    this.logoutEvent$ = this.logout$.asObservable();
  }

  login(cmd: LoginCommand): Observable<GetToken> {
    return this.authManagementApiService.authLogin({ loginCommand: cmd }).pipe(
      tap((token) => {
        if (token) {
          this.storeTokens(token);
          // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
          this.setLoginProperties(token.token!);
        }
      }),
    );
  }

  trialRegister(cmd: RegistrationCommand): Observable<unknown> {
    return this.authManagementApiService.authTrial({ registrationCommand: cmd });
  }

  setLoginProperties(token: string) {
    this.decodedToken = decodeToken(token);
  }

  getDecodedToken() {
    return this.decodedToken;
  }

  getToken(): string {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    return localStorage.getItem(TOKEN)!;
  }

  getRefreshToken(): string {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    return localStorage.getItem(REFRESH_TOKEN)!;
  }

  storeTokens(token: GetToken) {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    localStorage.setItem(TOKEN, token.token!);
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    localStorage.setItem(REFRESH_TOKEN, token.refreshToken!);
  }

  refreshToken(cmd: GetToken) {
    return this.authManagementApiService.authRefresh({ refreshTokenCommand: cmd }).pipe(
      tap((newToken) => {
        if (newToken) {
          this.storeTokens(newToken);
          // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
          this.setLoginProperties(newToken.token!);
        }
      }),
    );
  }

  forgottenPasswordToken(cmd: ForgotPasswordCommand): Observable<unknown> {
    return this.authManagementApiService.authForgotPassword({ forgotPasswordCommand: cmd });
  }

  resetPassword(data: ResetPasswordCommand): Observable<unknown> {
    return this.authManagementApiService.authResetPassword({ resetPasswordCommand: data });
  }

  loggedIn(): boolean {
    const token = localStorage.getItem(TOKEN);
    return !!token;
  }

  logout({ saveLocationForRedirect = false } = {}): void {
    this.logout$.next();
    this.removeTokens();
    this.dialog.closeAll();

    if (saveLocationForRedirect) {
      this.loginRedirectService.setLoginRedirectUrl(this.location.path());
    }

    this.router.navigate(['/login']);
  }

  private removeTokens(): void {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  }
}
